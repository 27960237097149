<template>
  <div class="product-wrap programme-lighting">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-lighting"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="txt-box no-indent pd-5">
          <p>
            城市公共照明系统是与人们生活息息相关的重要公共基础设施，随着城市化进程的加快，城市公共照明设施的需求量和建设规模日益增大，但城市公共照明的过快发展增大了对能源的需求和消耗，同时在管理方面也存在诸多不足。现有城市公共照明系统普遍存在设备维护成本高，灯具、线缆盗窃现象严重，能源浪费大等问题。 </p>
          <p>
            在能源日益短缺，温室效应越来越严重，国家和地方政府大力号召节能减排、绿色照明的情况下，能有效的控制能源消耗，提高路灯寿命，降低维护和管理成本，是现代效能型社会建设的目标，也是城市智慧化建设的必然趋势。当前，我国许多城市纷纷把智慧城市的建设提上日程，通过信息通信技术和智慧城市建设来完善城市公共服务和改善城市生活环境，使城市变得更加“智慧”。作为智慧型基础设施，智慧照明是智慧城市建设中重要的组成部分。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>行业痛点 </h4></div>
        <div class="pain-box mt-2">
          <div class="td-item">
            <img src="../../assets/img/programme/lighting/td_a.jpg" alt="能耗高，不节能。">
            <p class="color-59">能耗高，不节能。</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/lighting/td_b.jpg" alt="控制方式单一，亮灯时间不准确。">
            <p class="color-59">控制方式单一，亮灯时间不准确。</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/lighting/td_c.jpg" alt="巡查困难、故障处理不及时。">
            <p class="color-59">巡查困难、故障处理不及时。</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/lighting/td_d.jpg" alt="维护成本高。">
            <p class="color-59">维护成本高。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box">
          智慧照明系统包含智能照明、智能采集和监测、室内定位、云计算、无线通信、环境监测、视频监控、多媒体显示屏、WIFI、一键呼叫、广播、智慧灌溉、智慧闸机等一系列功能的平台化产品。提供了超高节能效率的绿色智慧照明，深度贴合业务场景的人员、车辆、物料等精准定位管理。实现了智能照明、绿色能源、智能安防、无线城市、互通互联、智能感知、智慧交通、智慧市政等诸多应用。
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-2">
        <div class="fz-big align-ct"><h4>核心功能</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhzm"></i></div>
            <p>智慧照明</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gwbj"></i></div>
            <p>环境监测</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-sxt"></i></div>
            <p>视频监控</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-daping"></i></div>
            <p>智能大屏</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-kongzhi"></i></div>
            <p>智能控制</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-baojing"></i></div>
            <p>报警管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-nengyuan"></i></div>
            <p>能源管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yscgq"></i></div>
            <p>故障检测</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhkz"></i></div>
            <p>综合管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-anquan"></i></div>
            <p>安防管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jieneng"></i></div>
            <p>节能管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-fenxi"></i></div>
            <p>报表分析</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-2">
        <div class="fz-big align-ct"><h4>方案优势</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jingxihua"></i></div>
            <p>多层次全面精细监控</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zidonghua"></i></div>
            <p>自动化综合运维管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-ydhbg"></i></div>
            <p>移动化敏捷高效运维</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-baobiao"></i></div>
            <p>多维度数据分析报告</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-nengyuan"></i></div>
            <p>智能化绿色能耗管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-anquan"></i></div>
            <p>全方位安全保障体系</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧照明-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧照明-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-lighting {
  .banner-lighting {
    background: url("../../assets/img/programme/lighting/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
